@use 'styles/styles' as *;

.input,
.inputGroup {
  width: fit-content;
  height: 40px;
  padding: 2px 16px;

  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  border-color: $bluishGray-200;

  background-color: $white;
  color: $bluishGray-400;
  @extend .text-style-body;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;

  &.solid,
  &.disabled {
    cursor: not-allowed;
    background-color: $bluishGray-100;
    color: $bluishGray-500;
    border-color: $bluishGray-300;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  &.read_only {
    background-color: $bluishGray-50;
    color: $bluishGray-700;
    border: none;
  }

  &:focus,
  &:focus-within {
    outline: none;
    color: $bluishGray-700;
  }

  &.focusBorder {
    color: $gray-600;

    &:focus:not(.invalid),
    &:focus-within:not(.invalid) {
      outline: none;
      padding: 0 14px;
      background-color: $white;
      color: $gray-600;
      border: 3px solid $coz-blue-300;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullHeight {
    height: 100%;
  }

  &.invalid {
    color: $gray-700;
    border-color: $peach-500;
  }

  &.size {
    &_small {
      @extend .text-style-t1;
    }

    &_medium {
      @extend .text-style-body;
    }
  }
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 7px;

  > input {
    width: 100%;
    height: 100%;
    border: none;
    flex-shrink: 1;
  }
}
