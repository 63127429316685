@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;

.bar_wrapper {
  display: flex;
  width: 100%;
  min-width: 928px;
  height: 92px;
  border: 1px solid c.$bluishGray-200;
  border-radius: 8px;

  .index_box {
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: c.$coz-blue-600;
    border-radius: 8px 0 0 8px;
    @include ts.text-style('body', 'bold');
  }

  .bar_content {
    display: flex;
    flex-grow: 1;
    padding: 21px 16px 22px 20px;
    justify-content: space-between;

    .main_content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .survey_title {
        width: 600px;
        color: c.$gray-800;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include ts.text-style('body', 'bold');
      }
    }

    .button_box {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}

.sb_standard_bars_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
