@use 'styles/styles' as *;

.content_wrapper {
  min-width: 960px;
}
.divider {
  width: 100%;
  min-height: 1px;
  max-height: 1px;
  background-color: $bluishGray-200;
}
.section_header {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    @extend .text-style-h4;
    color: $gray-700;
  }
}

section.information {
  padding: 28px 0 25px 0;
};

section.status {
  padding: 28px 0 42px 0;
};

.status_item_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}