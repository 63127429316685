@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;
@use 'src/styles/variables' as v;

.wrapper {
  position: relative;
  width: 100%;
}

.selected {
  width: 100%;
  height: 40px;
  padding: 0 16px;

  background-color: c.$white;
  border: 1px solid c.$bluishGray-300;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: box-shadow 0.2s, border-color 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &.invalid {
    border-color: c.$peach-600;
  }

  &_value {
    @include ts.text-style(t1, medium);
    color: c.$gray-800;
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.placeholder {
      color: c.$gray-600;
    }
  }

  &_arrow {
    color: c.$bluishGray-400;
  }
}

.popover {
  width: 100%;
  background-color: c.$bluishGray-50;
  border-radius: 6px;

  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);
  z-index: v.$zlayout__popover;
  &:hover {
    z-index: v.$zlayout__footer + 1;
  }
  display: none;
  box-shadow: 0 0 1px 0 hsla(0, 0%, 0%, 0.9), 0 8px 20px 0 hsla(0, 0%, 0%, 0.15);

  &.open {
    display: block;
  }
}

.option {
  padding: 4px 0 10px;
  max-height: 199px;
  overflow-y: scroll;

  display: grid;
  row-gap: 6px;
  list-style: none;

  &_item {
    width: 100%;
    padding: 6px 30px;
    color: c.$black;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      color: c.$coz-blue-600;
      background-color: c.$coz-blue-100;
    }

    > p {
      @include ts.text-style(t1, regular);
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.size_small {
      padding: 6px 16px;
    }
  }
}

.option_search {
  width: 100%;
  margin-top: 9px;
  padding: 0 15px 0 19px;
  position: relative;

  > input {
    width: 100%;
    height: 36px;
    padding: 0 11px;
    background-color: transparent;
    border-bottom: 1px solid c.$bluishGray-200;

    color: c.$bluishGray-700;
    @include ts.text-style(t1, regular);

    &::placeholder {
      color: c.$bluishGray-500;
    }
  }

  &_icon {
    color: c.$bluishGray-500;
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
  }

  &.size_small {
    padding: 0 16px;
    > input {
      padding: 0;
    }
    .option_search_icon {
      right: 14px;
    }
  }
}

.option_not_found {
  padding: 6px 30px;
  @include ts.text-style(t1, regular);
  color: c.$bluishGray-500;
}

// for storybook
.sb_wrapper {
  width: 654px;
}
