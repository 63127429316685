@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;
@use 'src/pages/EvaluationLogicPage/components/LogicBox/LogicBox.module' as *;

.left {
  display: grid;
  grid-template-columns: 128px 656px;
  column-gap: 12px;
}

.right {
  display: grid;
  grid-template-columns: 88px 696px;
  column-gap: 12px;
}

.logicGate {
  width: 128px;
}

.blocked {
  width: 100%;
  height: 40px;
  background-color: c.$bluishGray-100;
  border: 1px solid c.$bluishGray-300;
  border-radius: 4px;
  cursor: not-allowed;

  &.disabled {
    border: none;
  }
}
