@use '/src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;

.numberInput_wrapper {
  display: inline-flex;
  align-items: center;
}

.size_small {
  @include ts.text-style(t2, medium);
}

.size_medium {
  @include ts.text-style(t1, medium);
}

.size_large {
  @include ts.text-style(body, medium);
}

.numberInput {
  width: 48px;
  box-sizing: border-box;
  padding: 7.5px 11px;
  position: relative;
  top: 1px;
  border-bottom: 2px solid transparent;
  text-align: center;
  transition: border-color 0.3s;

  &::placeholder {
    color: c.$gray-500;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  &:focus {
    color: c.$bluishGray-700;
    border-color: c.$coz-blue-200;
  }
}

.numberInput_label {
  font-weight: 700;
  color: c.$coz-blue-600;
}

