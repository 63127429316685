@use 'styles/styles' as *;

.container {
  @extend .modal-page-wrapper;
  flex-direction: column;
}

.cover {
  width: 202px;
  height: 250px;
  background-image: url('../../assets/pending-approval.png');
  background-position: center;
  background-size: contain;
}

.title {
  @extend .text-style-h5;
  color: $gray-800;
  margin-top: 38px;
}

.description {
  @extend .text-style-body;
  color: $bluishGray-600;
  margin-top: 15px;
}