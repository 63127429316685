@use 'styles/styles' as *;

.wrapper {
  display: flex;
}

.progress_wrapper {
  display: flex;
  align-items: baseline;
  margin-right: 14px;
  .number_did {
    @extend .text-style-h4;
    color: $coz-blue-700;
    margin-right: 2px;
    &.done {
      color: $gray-600;
    }
  }
  .number_whole, .divider {
    @extend .text-style-body;
    font-weight: 700;
    color: $gray-600;
  }
  .divider {
    margin-bottom: 1px;
    margin-right: 1px;
  }
}

.tag {
  width: 81px;
  height: 31px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  > p {
    @extend .text-style-t1;
    font-weight: 500;
    color: white;
  }
  &.in_progress {
    background-color: $coz-blue-600;
  }
  &.done {
    background-color: $gray-600;
  }
}