@use 'styles/styles' as *;

.modal_container {
  position: fixed;
  z-index: $zpopup__modal;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation: showModal 0.2s forwards;

  &_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: default;
  }

  &_innerContent {
    position: relative;
  }
}

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closeModal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal_container_open {
  animation: showModal 200ms forwards;
}

.modal_container_close {
  animation: closeModal 200ms forwards;
}

// ----- modal content -----
.modal_content {
  width: 426px;
  height: 400px;
  padding: 38px 40px;
  background-color: $white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  box-shadow: 0px 10px 10px 0px #00000026;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.modal_content_large {
  width: 713px;
  height: 85vh;
  padding: 0;
}

// ----- modal header -----
.modal_header {
  width: 100%;
  min-height: 88px;
  flex-shrink: 0;
  padding-bottom: 25px;
  @extend .text-style-h4;
  color: $gray-800;

  &_title {
    padding-right: 24px;
  }

  &_subTitle {
    @extend .text-style-t1;
    color: $gray-600;
    white-space: pre-line;
    margin-top: 9px;
  }

  .btn_close {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}

.modal_header_large {
  height: fit-content;
  min-height: 79px;
  flex-shrink: 0;
  padding: 30px 53px 22px 53px;
  border-bottom: 1px solid $bluishGray-300;
  @extend .text-style-h5;

  //.modal_header_title {
  //  max-width: 450px;
  //}

  .btn_close {
    top: 30px;
    right: 46px;
  }
}

// ----- modal body -----
.modal_body {
  width: 100%;
  height: 100%;
}

.modal_body_large {
  padding: 26px 45px 26px 53px;
  overflow-y: hidden;

  .modal_body_scroll {
    height: 100%;
    overflow-y: scroll;
    padding: 12px 30px 12px 0;

    &::-webkit-scrollbar {
      border-top-right-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: $bluishGray-100;
      background-color: $bluishGray-100;
      width: 14px;
    }
  }
}

// ----- modal footer -----
.modal_footer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  column-gap: 9px;
}

.modal_footer_large {
  width: fit-content;
  margin-top: 0;
  position: absolute;
  top: 23px;
  right: 100px;
}
