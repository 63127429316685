.pagination_wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 45px;

  .right {
    position: absolute;
    right: 0px;
  }
}
.page_size_selectbox {
  width: 154px;
}
