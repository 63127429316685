@use 'styles/styles' as *;

.loading_fit_container {
  width: 100%;
  height: 219px;
  border-radius: 4px;
  border: 1px solid $bluishGray-300;
  background-color: $white;
}

.loading_full_container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.loading_loader_wrapper {
   width: fit-content;
   height: fit-content;
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }