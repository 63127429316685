@use 'styles/styles' as *;
@use '../SurveyCreatorPage.module.scss' as *;

.header {
  width: 100%;
  height: $header-height;
  border-bottom: 1px solid $bluishGray-300;

  &_content_wrapper {
    width: 100%;
    max-width: 1440px;
    min-width: 900px;
    margin: 0 auto;
    padding: 31px 51px 28px 65px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

section.title {
  min-width: 200px;

  .page_title {
    @extend .text-style-h3;
    color: $bluishGray-800;
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    margin-top: 5px;
    span {
      color: $bluishGray-700;
      font-weight: 400;
    }
    *:not(:last-child) {
      margin-right: 4px;
    }
  }
}

section.controller {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin: 18px 0 7px;
  }

  .edit_survey_title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    min-width: 80px;
    max-width: 400px;
    max-height: 60px;
    margin-right: 16px;

    > .title {
      @extend .text-style-h4;
      color: $gray-800;
      padding-right: 16px;
    }
    > .btn_edit {
      margin: 0px 0px 0px 0px;
      display: flex;
      align-items: center;
    }
  }
}
