@use 'styles/styles' as *;

.item_wrapper {
  @extend .text-style-t1;

  @extend .text-medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $bluishGray-300;
  border-radius: 8px;
  background: $white;
}
.inputs_container {
  width: 1000px;
  padding: 36px;
  display: flex;
  flex-direction: row;
  gap: 17px;
  &_input {
    height: 40px;
    &_left {
      position: relative;

      width: 222px;

      &_error {
        position: absolute;
        left: 0;
        bottom: -20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $peach-500;
      }
    }
    &_right {
      position: relative;
      width: 399px;
      &_error {
        position: absolute;
        left: 0;
        bottom: -20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $peach-500;
      }
    }
  }
  &_equal {
    margin: auto 0;
  }
  &_dropdown {
    width: 107px;
    height: 40px;
  }
}
.trash {
  margin-right: 32px;
  color: #b2bdd1;
  &:hover {
    cursor: pointer;
  }
  &:active {
    color: #455573;
  }
}

.variable_input {
  font-size: 14px !important;
}
