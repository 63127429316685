@use 'styles/styles' as *;

.download_sentence {
  width: 102px;
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.025em;
  margin-right: 33px;
  cursor: pointer;
  color: $gray-700;

  &.disabled {
    cursor: not-allowed;
    color: $gray-500;
    text-decoration: none;
    button {
      cursor: not-allowed;
    }
  }
}