@use 'styles/styles' as *;

.evaluation_logic_page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1000px;
  min-height: calc(100% - $page-footer--height);
  margin: 0 auto;
  padding: 40px 0 calc(72px + $page-footer--height);
}

.logics_view {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.empty_view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  > p {
    color: $bluishGray-500;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.025em;
  }
}
