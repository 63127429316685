$black: #000;
$white: #fff;

$gray-10: #f7f7f7;
$gray-50: #f2f2f2;
$gray-100: #f0f0f0;
$gray-200: #e6e6e6;
$gray-300: #d6d6d6;
$gray-400: #c2c2c2;
$gray-500: #a3a3a3;
$gray-600: #858585;
$gray-700: #5c5c5c;
$gray-800: #333333;
$gray-900: #141414;

$bluishGray-10: #f5f7f9;
$bluishGray-50: #f2f4f7;
$bluishGray-100: #eceff4;
$bluishGray-200: #dfe4ec;
$bluishGray-300: #ccd3e0;
$bluishGray-400: #b2bdd1;
$bluishGray-500: #8c9cba;
$bluishGray-600: #667ba3;
$bluishGray-700: #455573;
$bluishGray-800: #262f40;
$bluishGray-900: #0f1319;

$purple-10: #f4f2fd;
$purple-50: #efedfc;
$purple-100: #e8e5fb;
$purple-200: #d8d3f8;
$purple-300: #c0b9f4;
$purple-400: #a296ee;
$purple-500: #7361e5;
$purple-600: #452cdd;
$purple-700: #2c1a9e;
$purple-800: #190e58;
$purple-900: #0a0623;

$coz-blue-10: #f2f5fd;
$coz-blue-50: #edf2fc;
$coz-blue-100: #e4ebfb;
$coz-blue-200: #d2ddf9;
$coz-blue-300: #b8c9f5;
$coz-blue-400: #94adf0;
$coz-blue-500: #5e85e8;
$coz-blue-600: #295ce0;
$coz-blue-700: #173ea1;
$coz-blue-800: #0d2259;
$coz-blue-900: #050e24;

$orange-50: #fff2e9;
$orange-100: #ffebde;
$orange-200: #ffe1cc;
$orange-300: #ffcead;
$orange-400: #ffb685;
$orange-500: #ff9247;
$orange-600: #ff6e0a;
$orange-700: #ff6e0a;

$peach-50: #ffeeeb;
$peach-100: #ffe6e0;
$peach-200: #ffd5cc;
$peach-300: #ffbcad;
$peach-400: #ff9b85;
$peach-500: #ff6947;
$peach-600: #ff360a;
$peach-700: #ff360a;

$mint-50: #ebfff7;
$mint-100: #e0fff2;
$mint-200: #ccffea;
$mint-300: #adffdd;
$mint-400: #85ffcc;
$mint-500: #47ffb2;
$mint-600: #0aff98;
$mint-700: #00b86a;
$mint-800: #00663b;

$black-alpha-10: rgba(0, 0, 0, 0.03);
$black-alpha-50: rgba(0, 0, 0, 0.04);
$black-alpha-100: rgba(0, 0, 0, 0.06);
$black-alpha-200: rgba(0, 0, 0, 0.1);
$black-alpha-300: rgba(0, 0, 0, 0.16);
$black-alpha-400: rgba(0, 0, 0, 0.24);
$black-alpha-500: rgba(0, 0, 0, 0.36);
$black-alpha-600: rgba(0, 0, 0, 0.48);
$black-alpha-700: rgba(0, 0, 0, 0.64);
$black-alpha-800: rgba(0, 0, 0, 0.8);
$black-alpha-900: rgba(0, 0, 0, 0.92);
