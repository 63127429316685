@use 'styles/styles' as *;

.modal_content {
  text-align: center;
  margin-bottom: 25px;
  @extend .text-style-h5;
}

.page_contents {
  margin: 34px 0;
  width: 1011px;
}

.content_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchbox_wrapper {
  width: 281px;
}

.survey_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 18px;
  margin-bottom: 34px;
  grid-auto-rows: 10px;
}

.masonry_grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  row-gap: 18px;
  column-gap: 18px;
}

.survey_set_item {
  margin-bottom: 18px;
}

.content_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.selectbox_wrapper {
  width: 146px;
}

.blank_for_layout {
  width: 146px;
}

.button_box {
  width: 165px;
}