@use 'styles/styles' as *;

.query_value_selectbox {
  height: 40px;
  display: flex;
}
.dropdown_middle {
  width: 345px;
  border: 1px solid $gray-200;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $gray-700;
}
