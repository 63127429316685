@use 'styles/styles' as *;

$column-gap: 26px;

.items_wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: $column-gap;
}

.item_wrapper {
  display: grid;
  width: calc(50% - $column-gap/2);
  height: 48px;
  grid-template-columns: 93px auto;
};

.label {
  display: flex;
  align-items: center;

  > p {
    @extend .text-style-body;
    font-weight: 700;
    color: $gray-600;
    text-align: left;
  }
};

.value {
  background-color: $gray-50;
  border-radius: 6px;
  border: solid 1px #E2E8F0;

  width: 100%;
  display: flex;
  align-items: center;
  padding : 12px 16px;

  > p {
    @extend .text-style-body;
    font-weight: 400;
    color: $gray-600;
  }
}
