@use 'styles/styles' as *;

.section_content {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  gap: 24px;
}

.set_info {
  display: flex;
  margin-top: 10px;

  .input_wrapper {
    height: 40px;
    width: 345px;
  }
}

.course_info {
  display: flex;

  .inputs_wrapper {
    display: flex;
    gap: 16px;

    .input_wrapper {
      height: 40px;
      width: 132px;
    }
  }
}

.sub_content {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.section_subtitle {
  color: bluishGray-700;
  margin-right: 16px;
  @extend .text-regular;
  min-width: 82px;

  strong {
    color: red;
  }
}

.id {
  color: $bluishGray-500;
}

.is_slugurl_korean {
  display: flex;
  align-self: center;
  color: $peach-500;
  @extend .text-style-t1;
}
