@use 'styles/styles' as *;

.page_footer {
  @extend .content-wrapper;
  background-color: $bluishGray-10;
  padding-top: 0;
  padding-bottom: 0;
  transition: width 0.3s ease;
  max-width: 100%;
  height: $page-footer--height;
  border-top: 1px solid $bluishGray-300;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $zlayout__footer;

  @each $status, $width in $sidebar-width {
    &.sidebar_#{$status} {
      width: calc(100vw - $width);
    }
  }
}

.content_wrapper {
  @extend .center-align;
  display: flex;
  justify-content: space-between;
  letter-spacing: -0.025em;
}

.left {
  padding-top: 30px;
  height: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title {
  color: $bluishGray-700;
  font-weight: 700;
  font-size: 20px;
  margin-right: 8px;
}
.count {
  color: $coz-blue-600;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}

.right {
  padding-top: 25px;
}
.init_btn {
  color: $bluishGray-600;
  margin-right: 32px;
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  letter-spacing: -0.025em !important;
}
.add_btn {
  margin-right: 16px;
}
.unannounced {
  color: $bluishGray-600;
  margin-top: 11px;
  font-size: 12px;
}
