@use 'styles/styles' as *;

$header-height: 124px;

.flex { 
  display: flex;
}
.surveycreator {
  width: 100%;
  height: 100vh;
  background-color: $bluishGray-10;
}

.embedded_wrapper {
  width: 100%;
  height: calc(100vh - $header-height);
  overflow-y: scroll;
}

.modal_input {
  width: 100%;
  padding: 13px 16px;
  border-radius: 6px;
  border: 1px solid $bluishGray-200;
  @extend .text-style-body;
  color: $bluishGray-700;
}