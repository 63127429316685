@use 'styles/styles' as *;

.error_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  text-align: center;
  > p {
    @extend .text-style-t1;
    font-weight: 500;
    color: $bluishGray-500;
    white-space: pre-line;
  }
}
