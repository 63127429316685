@use 'styles/styles' as *;

.wrapper {
  display: flex;
  justify-content: center;
}
.underline {
  width: fit-content;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}
.copiable {
  margin-right: 8px;
}
.red {
  color: $peach-500;
}
.blue {
  color: $coz-blue-500;
}
.gray {
  color: $gray-700;
}
.black {
  color: black;
}
