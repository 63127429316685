@use 'styles/styles' as *;

.container {
  @extend .modal-page-wrapper;
}

.modal{
  &_wrapper {
    width: 360px;
    height: 450px;
    background-color: #ffffff;
    border: 1px solid $bluishGray-400;
    border-radius: 18px;;
    padding: 40px; 

    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));

    display: grid;
    grid-template-rows: 50px auto 51px;
    align-content: space-between;
  }

  &_contents {
    display: flex;
    align-items: center;

    .title {
      @extend .text-style-h3;
      color: $purple-800;
      white-space: pre-line;
      > span {
        color: $coz-blue-600;
      }
    }
  }
}

.logo {
  margin-left: 4px;
  margin-top: 13px;
}

.login_input {
  height: 100px;
}

.button_normal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  border-radius: 6px;
  background-color: $coz-blue-600;
  transition: all 0.3s ease;

  > p {
    @extend .text-style-h5;
    color: $white;
  }

  &:hover {
    background-color: $coz-blue-700;
  }
}

.button_google {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  border-radius: 6px;
  background-color: $peach-600;
  transition: all 0.3s ease;

  > p {
    @extend .text-style-h5;
    color: $white;
  }
  
  &:hover {
    background-color: $peach-700;
  }
}