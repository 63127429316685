@use 'styles/styles' as *;

.tag {
  width: 53px;
  height: 23px;
  border-radius: 5px;
  color: $white;
  display: flex;
  @extend .text-style-t2;

  &.coz {
    background: $purple-500;
  }
  &.wewin {
    background: $coz-blue-500;
  }
  &_span {
    line-height: 1.8;
    margin: 0 auto;
  }
}
