@use 'src/styles/colors.scss' as c;

.badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 3px;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.025em;

  &.completed {
    width: 58px;
    background-color: c.$coz-blue-500;
  }

  &.incomplete {
    width: 46px;
    color: c.$bluishGray-500;
    background-color: c.$bluishGray-200;
  }

  &.error {
    width: 58px;
    background-color: c.$peach-400;
  }
}

.sb-badges-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
