@use 'styles/styles' as *;

.survey_section_wrapper {
  padding-right: 17px;
}

.button_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.blank_section {
  text-align: center;
  color: $bluishGray-500;
  padding: 36px;
  border: 1px dashed $bluishGray-400;
  border-radius: 6px;
  @extend .text-regular;
}
