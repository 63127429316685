@use 'styles/styles' as *;

.modal_wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}
.input_error_message {
  margin: 11px 0 0 16px;
  color: $peach-500;
}
