@use 'styles/styles' as *;

.pagenation {
  width: fit-content;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.page_number {
  font-size: 15px;
  color: $bluishGray-600;
  margin: 0 25px;
}

.move_button {
  background-color: $bluishGray-100;
  color: $bluishGray-600;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: $bluishGray-300;
    color: $coz-blue-600;
  }
  &:disabled,
  &:disabled:hover {
    background-color: $bluishGray-50;
    cursor: not-allowed;
    color: $bluishGray-300;
  }
  > svg {
    width: 18px;
    height: 18px;
    margin: 5px;
  }
}
