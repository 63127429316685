@use 'styles/styles' as *;

.bar_wrapper {
  display: flex;
  margin-top: 20px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 18px;
  }
}

.bar {
  width: 828px;
  display: flex;
  align-items: center;
  padding: 28px;
  border: 1px solid $bluishGray-300;
  border-radius: 6px;

  svg {
    color: $coz-blue-600;
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }

  .setting_icon {
    margin-right: 16px;
  }

  &.disabled {
    background: $bluishGray-100;
    cursor: not-allowed;
    svg {
      color: $bluishGray-400;
    }
  }
}

.input_wrapper {
  width: 515px;
  margin-right: 110px;
  input {
    width: 100%;
  }
}

.preview_button {
  margin-right: 24px;
}
