.equal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  gap: 3px;
  &_black {
    color: black;
    background-color: black;
    width: 10px;
    height: 2px;
  }
}
