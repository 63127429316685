@use 'styles/styles' as *;

.tab_wrapper {
  width: 427px;
  background-color: $bluishGray-100;
  border: 1px solid $bluishGray-300;
  padding: 16px 14px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  svg {
    width: 16px;
    height: 16px;
    color: $bluishGray-500;
  }
}

.info {
  display: flex;
  align-items: center;
}

.institution {
  color: $bluishGray-500;
  margin: 0 15px 0 9px;
  @extend .text-style-t1;
}

.name {
  color: $bluishGray-700;
  margin-left: 10px;
  @extend .text-regular;
}

.svg_wrapper {
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
