@use 'styles/styles' as *;

.wrapper {
  width: 100%;
  min-height: 100px;
  display: grid;
  grid-template-columns: 49px auto;
}

.icon {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background-color: $bluishGray-200;

  display: flex;
  align-items: center;
  justify-content: center;

  &_done {
    background-color: $coz-blue-600;
  }

  &_number {
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%);
    
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
  }

  &_error {
    background-color: $bluishGray-400;
  }
}

.content {
  background-color: $white;
  border: 1px solid $bluishGray-400;
  border-left: none;
  border-radius: 0 10px 10px 0;

  padding: 24px 21px;
  display: grid;
  grid-template-columns: 250px auto;
  grid-template-rows: auto 24px;

  .title {
    grid-column: 1/3;
    @extend .text-style-body;
    color: $gray-600;
    font-weight: 700;
  }
  .createdAt {
    @extend .text-style-t1;
    font-weight: 400;
    line-height: 1em;
    display: flex;
    align-items: flex-end;
    color: $gray-500;
  }
  .controller {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: flex-end;
    column-gap: 17px;


    .btn_cancel {
      @extend .text-style-body;
      line-height: 1em;
      color: $gray-600;
      text-decoration: underline;
      margin-right: 9px;
    }

    .btn_view, .btn_copy {
      display: flex;
      align-items: center;
      column-gap: 6px;

      @extend .text-style-body;
      line-height: 1em;
      color: $coz-blue-600;
      > svg > path {
        fill: $coz-blue-600;
      }
    }
  }
}
