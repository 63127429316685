@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;
@use 'src/styles/variables' as v;
@use 'src/pages/EvaluationLogicPage/components/LogicBox/LogicBox.module' as *;

.execution_type {
  width: 132px;
}

.textReplacement {
  &_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 180px 44px 570px;
    align-items: center;
    justify-items: center;
  }
}

.arithmetic {
  &_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 180px 60px 102px 416px;
    column-gap: 12px;
    align-items: center;
    position: relative;
  }

  &_text {
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    z-index: v.$zlayout__hierarchy;

    color: c.$bluishGray-500;
    @include ts.text-style(t2, medium);
  }
}
