@use 'styles/styles' as *;

.top_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}

.content_wrapper {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.download_wrapper {
  width: 100%;
  margin-right: 12px;
  display: flex;
  justify-content: space-between;
}

.query_value_table {
  margin: 0 auto 14px;
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.025em;
}

.toast_position {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.memo_btn {
  padding: 3.5px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  border-radius: 4px;
  white-space: nowrap;

  &_true {
    border: 1px solid $coz-blue-600;
    color: $coz-blue-600;
  }
  &_false {
    border: 1px solid $gray-500;
    color: $gray-500;
  }
}
