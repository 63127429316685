@use 'styles/colors' as c;

.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: c.$white;
  color: c.$white;
  border: 1.7px solid c.$bluishGray-400;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.small {
  width: 12px;
  height: 12px;
}
.medium {
  width: 16px;
  height: 16px;
}
.large {
  width: 20px;
  height: 20px;
}

.invalid:not(.checked):not(.disabled) {
  border-color: c.$peach-500;
}

.checked:not(.disabled) {
  border-color: c.$coz-blue-600;
  background: c.$coz-blue-600;
  color: c.$bluishGray-10;
  &:hover {
    border-color: c.$coz-blue-500;
    background: c.$coz-blue-500;
    color: c.$coz-blue-300;
  }
}

.disabled {
  border-color: c.$bluishGray-200;
  background: c.$bluishGray-200;
  color: c.$bluishGray-10;
  cursor: not-allowed;
}
