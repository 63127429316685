@use 'styles/styles' as *;

.temporary_cover {
  width: 100%;
  height: calc(100vh - 85px);
  min-height: 353px;
  background-image: url('../../assets/temp-dashboard.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
};