@use 'styles/styles' as *;

.layout {
  min-height: 100vh;
  transition: 0.3s ease;
  background-color: $bluishGray-10;
  overflow: auto;
  display: grid;
  grid-template-areas: 'sidebar main';

  @each $status, $width in $sidebar-width {
    &.sidebar_#{$status} {
      grid-template-columns: $width 1fr;
    }
  }

  &.fullscreen {
    display: initial;
  }
}

.sidebar {
  grid-area: sidebar;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
}
.outlet {
  flex: 1;
}
