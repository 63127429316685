@use 'styles/styles' as *;

.set_editor_page_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 63px;
}

.main_contents {
  padding: 30px 0 20px;
}

.content_footer {
  width: 960px;
  display: flex;
  justify-content: space-between;
}

.date_info {
  display: flex;
  align-items: flex-end;
  color: $bluishGray-600;
  font-weight: 400;
}

.button_box {
  button {
    margin-left: 10px;
  }
}

.survey_searchbox {
  width: 341px;
  height: 40px;
}

.alert_modal {
  text-align: center;
  @extend .text-style-h5;
}

.modal_description {
  margin-top: 8px;
  text-align: center;
  color: $coz-blue-500;
  @extend .text-style-t1;
  @extend .text-medium;
}

.survey_setting_modal {
  transform: translateY(-20px);
}

.setting_item {
  margin-bottom: 17px;

  input {
    margin: 8px 5px 0px 20px;
  }

  .alert_message {
    margin-left: 36px;
    margin-top: 4px;
    color: $peach-500;

    @extend .text-style-t1;
    @extend .text-medium;
  }

  .hidden {
    visibility: hidden;
  }
}

.setting_item_title {
  display: flex;
  align-items: center;
  color: $bluishGray-600;
  @extend .text-style-body;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  span {
    margin-left: 2px;
    color: red;
  }

  &_input {
    width: 301px;
  }
}

.setting_item_input {
  &_title {
    width: 301px;
  }

  &_time {
    > input {
      width: 52px;
    }
  }
}