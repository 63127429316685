@use 'styles/styles' as *;

.container {
  @extend .modal-page-wrapper;
}

.modal{
  &_wrapper {
    width: 360px;
    height: 446px;
    background-color: #ffffff;
    border: 1px solid $bluishGray-400;
    border-radius: 18px;;
    padding: 46px 40px 40px 40px; 

    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));

    display: grid;
    grid-template-rows: 62px 112px auto;
  }

  &_title {
    margin-left: 4px;
    @extend .text-style-h3;
    color: $purple-800;
  }

  &_description {
    background-color: $coz-blue-100;
    border-radius: 6px;
    padding: 12px 14px;
    margin-bottom: 25px;
    > p {
      @extend .text-style-t1;
      color: $bluishGray-700;
    }
  }

  &_form {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }


}

.button {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: $coz-blue-600;
  transition: all 0.3s ease;
  &:hover {
    background-color: $coz-blue-700;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}