@use 'styles/styles' as *;

.Toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: 40px;

  padding: 0 13px 0 10px;
  border-radius: 6px;

  &_positive {
    background-color: $mint-100;
    .btn-close > path {
      stroke: $bluishGray-700;
    }
  }
  &_negative {
    background-color: $bluishGray-200;
    .btn-close > path {
      stroke: $bluishGray-800;
    }
  }

  &_icon {
    margin-right: 12px;
  }
  &_message {
    @extend .text-style-t1;
    color: $bluishGray-800;
  }
  .btn_close {
    margin-left: 14px;
    margin-bottom: 2px;
    cursor: pointer;
  }
}
