@use 'styles/styles' as *;

.searchbox_wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
  height: fit-content;
  width: fit-content;
}

.searchbox {
  color: $gray-600;
  border: 1px solid $gray-300;
  border-radius: 4px;
  height: 3em;
  padding: 0 1em;
  font-weight: 400;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s;
  box-sizing: border-box;
  background-color: white;

  &:hover,
  &.searching {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }

  > svg {
    color: #828282;
    width: 1.2em;
    height: 1.2em;
  }

  > input {
    background-color: transparent;
    flex: 1;

    &::placeholder {
      color: $gray-300;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    outline: none;
  }
}

.searchbox_value {
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  color: $gray-600;
  margin: {
    right: 0.5em;
    top: auto;
    bottom: auto;
  }
}

.options {
  right: 0;
  margin-top: 7.5px;
  z-index: $zlayout__popover;
  position: absolute;
  background-color: $bluishGray-50;
  border-top: none;
  border-radius: 6px;
  max-height: 500px;
  min-width: 100%;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.9);

  &.upward {
    bottom: 3em;
    margin-bottom: 7.5px;
  }

  &.hidden {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.item_button {
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: center;
  padding-right: 1em;
  transition: background-color 0.2s, color 0.2s;

  > .check {
    flex-shrink: 0;
    transition: color 0.2s;
    margin: {
      top: 0.3em;
      right: 0.2em;
      left: 1em;
    }
    height: 1.2em;
    color: #dfe3ec;
  }

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    > .check {
      color: $bluishGray-600;
    }

    color: $coz-blue-600;
    background-color: $coz-blue-100;
  }
}

.empty_space {
  position: sticky;
  width: 100%;
  height: 10px;

  &.top {
    background: linear-gradient(to bottom, $bluishGray-50, rgba(255, 255, 255, 0));
    top: 0;
  }

  &.bottom {
    background: linear-gradient(to top, $bluishGray-50, rgba(255, 255, 255, 0));
    bottom: 0;
  }
}
