@use 'styles/styles' as *;

section {
  position: relative;
}

.search_wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin: 34px 0 20px;

  .searchbox_wrapper {
    width: 281px;
  }
}

.query_value_table {
  margin: 0 52px 28px 0;
  width: 100%;
}

.toast_position {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.nothing_search_result {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.nothing_search_result {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.table_text_button {
  &__underlined {
    width: fit-content;
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
  }

  &__disabled {
    cursor: unset;
  }
}
