@use 'styles/styles' as *;

.selectbox_wrap {
  position: relative;
  display: inline-block;
  text-align: center;
  height: fit-content;
  width: 100%;
  &.isFullHeight {
    height: 100%;
  }
}

.selectbox {
  color: $gray-600;
  background-color: white;
  border: 1px solid $gray-300;
  border-radius: 4px;
  height: 3em;
  padding: 0 1em;
  font-weight: 400;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s;
  box-sizing: border-box;
  width: 100%;
  &:hover,
  &.open {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &.invalid {
    border-color: $peach-600;
  }
}
.selectbox_value {
  width: 100%;
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.placeholder {
    color: $gray-600;
  }
}

.triangle {
  width: 0px;
  height: 0px;

  border-top: 7px solid $gray-300;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  &.upward {
    border-top: none;
    border-bottom: 7px solid $gray-400;
  }
}
.label {
  color: $gray-600;
  margin: {
    right: 0.5em;
    top: auto;
    bottom: auto;
  }
}

.options {
  right: 0;
  margin-top: 7.5px;
  z-index: $zlayout__popover;

  &:hover {
    z-index: $zlayout__footer + 1;
  }
  position: absolute;
  background-color: $bluishGray-50;

  border-top: none;
  border-radius: 6px;
  max-height: 500px;
  min-width: 100%;

  overflow-y: auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.9);
  // @include fade-in(0.3s);
  &.upward {
    bottom: 3em;
    margin-bottom: 7.5px;
  }
  &.hidden {
    display: none;
  }
  &.overflow_scroll {
    max-height: 209px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.item_button {
  width: 100%;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: background-color 0.2s, color 0.2s;

  &.align_left {
    justify-content: left;
  }

  &.with_check {
    justify-content: space-between;
    padding-left: 20px;
  }

  > .check {
    flex-shrink: 0;
    transition: color 0.2s;
    margin: {
      top: 0.3em;
      right: 0.2em;
    }
    height: 1.2em;
    color: #dfe3ec;
  }

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    > .check {
      color: $coz-blue-600;
    }
    color: $coz-blue-600;
    background-color: $coz-blue-100;
  }
}

.empty_space {
  position: sticky;
  width: 100%;
  height: 10px;
  &.top {
    background: linear-gradient(to bottom, $bluishGray-50, transparent);
    top: 0;
  }
  &.bottom {
    background: linear-gradient(to top, $bluishGray-50, transparent);
    bottom: 0;
  }
}
