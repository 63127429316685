@use 'src/styles/colors.scss' as c;

.searchBox {
  border-color: c.$bluishGray-300;
  background-color: c.$bluishGray-100;
  border-radius: 4px;

  &> input::placeholder {
    color: c.$gray-600;
  }
}