@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles.scss' as ts;

.search_wrapper {
  display: flex;
  position: relative;
  padding-right: 18px;
  height: 40px;
  @include ts.text-style(t1, regular);
}

.query_value_selectbox {
  width: 135px;
  height: 100%;
  margin-right: 5px;
}

.query_value_searchbox {
  width: 281px;
  height: 100%;
}

.dropdown_searchbox {
  height: 100%;

  background-color: c.$bluishGray-100;
  border-color: c.$bluishGray-300;
}
