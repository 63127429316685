@use 'styles/styles' as *;

.preview {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding-right: 10px;
}

.wrapper {
  .question {
    @extend .text-style-body;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .answer {
    background-color: $bluishGray-50;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid $bluishGray-200;
    p {
      @extend .text-style-body;
    }
    &.disabled {
      cursor: not-allowed;
      background-color: $bluishGray-100;
      border-color: $bluishGray-300;
      p {
        color: $bluishGray-500;
      }
    }
  }
}
