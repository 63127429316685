@use 'styles/styles' as *;

.wrapper {
  position: relative;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
}

.before_hover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_md {
    max-width: 80px;
  }
  &_lg {
    max-width: 300px;
  }
}

.after_hover {
  display: flex;
  padding: 5px 8px;
  border: 1.5px $bluishGray-300 solid;
  border-radius: 5px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: $zlayout__tooltip;
  min-width: fit-content;
  white-space: nowrap;
  background-color: white;
}
