@use 'styles/styles' as *;

.wrapper {
  padding-top: 28px;
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
}

.radio {
  &_wrapper {
    position: relative;
    display: flex;
    column-gap: 13px;
    align-items: center;
    padding-left: 29px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @extend .text-style-body;
    color: $gray-700;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      & ~ .mark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $white;
        border: solid 2px $coz-blue-600;
        border-radius: 50%;
      }
      &:checked ~ .mark {
        background-color: $coz-blue-600;
        border: solid 2px $coz-blue-400;
      }
    }    
  }
}

