.text {
  letter-spacing: -0.025em;
  line-height: 1.5em;

  &-bold {
    font-weight: 700;
  }

  &-medium {
    font-weight: 500;
  }

  &-regular {
    font-weight: 400;
  }

  &-style {
    &-h1 {
      @extend .text;
      font-size: 2.25rem;
      font-weight: 700;
    }

    &-h2 {
      @extend .text;
      font-size: 1.875rem;
      font-weight: 700;
    }

    &-h3 {
      @extend .text;
      font-size: 1.5rem;
      font-weight: 700;
    }

    &-h4 {
      @extend .text;
      font-size: 1.25rem;
      font-weight: 700;
    }

    &-h5 {
      @extend .text;
      font-size: 1.125rem;
      font-weight: 700;
    }

    &-body {
      @extend .text;
      font-size: 1rem;
      font-weight: 500;
    }

    &-t1 {
      @extend .text;
      font-size: 0.875rem;
      font-weight: 400;
    }

    &-t2 {
      @extend .text;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
}

$font-size: (
  'h4': 1.25rem,
  'h5': 1.125rem,
  'body': 1rem,
  't1': 0.875rem,
  't2': 0.75rem,
);
$font-weight: (
  'bold': 700,
  'medium': 500,
  'regular': 400,
);

@mixin text-style($size, $weight: regular) {
  font-size: map-get($font-size, $size);
  line-height: 1.5em;
  letter-spacing: -0.025em;
  font-weight: map-get($font-weight, $weight);
}
