@use 'styles/styles' as *;

@keyframes openWrapper {
  0% {
    overflow-y: hidden;
    height: 0;
  }
  99% {
    overflow-y: hidden;
    height: 231px;
  }
  100% {
    overflow: visible;
  }
}

@keyframes closeWrapper {
  0% {
    height: 231px;
    overflow-y: hidden;
  }
  100% {
    height: 0;
    overflow-y: hidden;
  }
}

.dropdown_wrapper {
  width: 100%;
  min-width: 1010px;
  height: 231px;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0 0 0 1px $bluishGray-300 inset;
  border-radius: 8px;

  &.close {
    animation: closeWrapper 0.5s ease-in-out forwards;
  }
  &.open {
    animation: openWrapper 0.5s ease-in-out forwards;
  }
}

.dropdown_container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  column-gap: 30px;

  > .left {
    height: fit-content;
    margin-top: 28px;
    margin-left: 21px;
    color: $coz-blue-500;
    white-space: nowrap;
  }

  > .right {
    //width: 100%;
    margin-top: 18px;
    margin-bottom: 20px;
    margin-right: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 30px;
  }
}

.dropdown_content {
  display: grid;
  grid-template-columns: 70px auto;

  &_title {
    width: 100%;
    display: flex;
    align-self: center;
    color: $gray-600;
  }
}
.selectbox {
  width: 100%;
}
.dropdown_middle {
  width: 192px;

  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.025em;
}
