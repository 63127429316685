@use 'styles/styles' as *;

.full_screen_loader {
  z-index: $ztransition__loader;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blurred_background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $bluishGray-10;
  opacity: 0.6;
}
