@use 'styles/styles' as *;
@use 'sass:map';

.side_bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: white;
  color: $gray-600;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  transition: width 0.3s ease;
  z-index: $zlayout__sidebar;

  @each $status, $width in $sidebar-width {
    &.#{$status} {
      width: $width;
    }
  }
}
.background_layer {
  position: absolute;
  background-color: $gray-10;
  height: 100%;
  width: map.get($sidebar-width, 'collapsed');
}
.link_wrap {
  position: relative;
  min-width: map.get($sidebar-width, 'collapsed');
  max-width: map.get($sidebar-width, 'collapsed');
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
  padding: 0 21px;
  height: 40px;
  &:hover {
    background-color: $coz-blue-100;
  }
  &.active {
    color: $coz-blue-600;
    background-color: $coz-blue-100;
  }
}

.link_icon {
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: $gray-50;
    color: $gray-900;
  }
}

.menu_title {
  display: flex;
  height: 41px;
  > h1,
  > h3 {
    padding: 15px 21px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  > h3 {
    width: 100%;
    white-space: nowrap;
    transition: color 0.3s;
    @extend .text-style-h5;
    &:hover {
      color: $gray-900;
    }
  }
  &.first {
    height: 72px;
    padding-top: 14px;
  }
  a {
    width: 100%;
    &.active {
      color: $coz-blue-600;
    }
  }
}

.detail_button {
  transition: background-color 0.4s;
  border-radius: 50%;
  &:hover {
    background-color: white;
  }
}

.sub_menu_list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s;
  &.expanded {
    max-height: 10em;
  }
}

.sub_menu {
  margin-left: map.get($sidebar-width, 'collapsed');
  white-space: nowrap;
  @extend .text-style-t1;
}

.logout_button_box {
  z-index: $zlayout__backdrop;
  position: absolute;
  bottom: 20px;
  right: 24px;
  width: 203px;
  height: 40px;
}
