@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;

.alert {
  width: 426px;
  padding: 38px 40px;
  position: relative;
  background-color: c.$white;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 #00000026;

  &_btn_close {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  &_content {
    width: 100%;
    height: 100%;
    min-height: 202px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
}

.alert_title {
  padding-top: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include ts.text-style(h5, bold);
  color: c.$bluishGray-800;
  white-space: pre-line;

  &_caption {
    margin-top: 8px;
    @include ts.text-style(t1, medium);
    color: c.$bluishGray-700;
    white-space: pre-line;

    &.tinted {
      color: c.$coz-blue-500;
    }
  }
}

.alert_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 9px;
}
