@use 'styles/styles' as *;

.wrapper {
  width: 1000px;

  margin: 40px auto 113px;
  padding-bottom: 112px;

  &_variables {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &_item {
      gap: 16px;
    }
  }
}
