@use 'styles/styles' as *;

.template_wrapper {
  width: 960px;
  background: $white;
  padding: 30px 38px;
  border-radius: 6px;
  border: 1px solid $bluishGray-400;
  margin-bottom: 20px;
}

.section_title {
  color: $gray-800;
  margin-bottom: 20px;
  @extend .text-style-h4;
  strong {
    color: red;
  }
}
