@use 'styles/styles' as *;

.contents_wrapper {
  min-width: 960px;
  display: flex;
  padding: 40px 64px;

  .contents_box {
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #CCD3E0;
    border-radius: 8px;

    .contents_header {
      padding: 30px 36px 32px;
      border-bottom: 1px solid #DFE3EC;

      > p {
        color: $gray-700;
        margin-bottom: 16px;
        @extend .text-style-h4;
      }

      .header_inner {
        display: flex;
        align-items: center;

        .select_box_wrapper {
          height: 40px;
          width: 415px;

          .select_box {
            height: 40px;
          }
        }

        > p {
          color: $bluishGray-600;
          @extend .text-style-t2;
        }
      }
    }
  }

  .contents_main {
    flex-grow: 1;

    &.not_chosen {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .standard_bar_box {
      height: 100%;
      padding: 40px 36px 36px 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .standard_bar_main {
        > p {
          color: $gray-700;
          margin-bottom: 16px;
          @include text-style(h4, bold);
        }

        > ul {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 40px;
        }
      }

      .setting_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > p {
          color: $gray-700;
          @include text-style(h4, bold);
        }
      }
    }

    .info_box {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        margin-bottom: 8px;
      }

      p {
        color: $bluishGray-400;
        @include text-style(t1, medium);
      }
    }
  }
}
