@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles.scss' as ts;

$radio-size: 16px;

.radio {
  width: $radio-size;
  height: $radio-size;
  min-height: $radio-size;
  position: relative;
  display: inline-flex;

  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
}

.radio_origin {
  position: relative;
  opacity: 0;
  width: $radio-size;
  height: $radio-size;
  cursor: pointer;
}

.radio_custom {
  position: absolute;
  left: 0;
  height: $radio-size;
  width: $radio-size;
  background-color: c.$white;
  border: solid 2px c.$coz-blue-600;
  border-radius: 50%;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.radio_origin:checked ~ .radio_custom {
  background-color: c.$coz-blue-600;
  border: solid 2px c.$coz-blue-400;
}

.radio_origin:disabled ~ .radio_custom {
  background-color: c.$coz-blue-100;
  border: solid 2px c.$gray-200;
}

.radio_item_wrapper {
  display: flex;
  align-items: center;
  column-gap: 13px;
}

.radio_label {
  @include ts.text-style(body, medium);
  color: c.$gray-600;
  user-select: none;
  position: relative;
  top: 1px;
}