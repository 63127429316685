@use 'variables' as v;
@use 'colors' as c;

.center-align {
  width: 100%;
  max-width: 1200px;
  min-width: 768px; // temporary value
  margin: 0 auto;
  padding: 0px 60px;
}
.content-wrapper {
  @extend .center-align;
  padding-bottom: 80px;

  &.expanded {
    min-height: calc(100vh - v.$page-title--height);
  }
}

.modal-page-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: c.$bluishGray-10;
}
