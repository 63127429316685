@use 'styles/styles' as *;

.table {
  background-color: $white;
  box-shadow: 0 0 0 1px $bluishGray-300;
  border-radius: 8px 8px 0px 0px;
  max-height: 3000px;
  transition: max-height 0.5s;
  width: 100%;
}

.th,
.td {
  text-align: center;
  font-weight: 400;
  vertical-align: middle;
  &.default {
    max-width: fit-content;
    width: fit-content;
    min-width: fit-content;
  }
  &.resizable {
    max-width: 1em;
    width: 30%;
  }
}

.th > div {
  padding: 0 1em;
  display: flex;
  height: 3em;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}

.td_contents_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-800;
  transition: background-color 0.3s;

  &:first-child {
    margin-left: 0.5em;
  }
  &:last-child {
    margin-right: 0.5em;
  }
}

.tr {
  @extend .text-style-t1;
  color: $gray-800;

  width: 100%;
  height: 46px;
}

.thead > .tr {
  position: relative;
  font-weight: 500;
  border-bottom: 2px solid $gray-50;
}

.tbody > .tr {
  font-size: 13px;

  transition: background-color 0.3s;
  border-bottom: 2px solid $gray-50;

  &:last-of-type {
    border-bottom: 1px solid $gray-50;
  }
  &:hover {
    background-color: $bluishGray-10;
  }
}

.button {
  svg:hover {
    cursor: pointer;
  }
}

.bottom {
  height: 12px;
}
