@use 'styles/styles' as *;

.cut_off_line_setting {
  display: flex;
  align-items: center;
  gap: 24px;

  .score_part {
    display: flex;
    align-items: center;
    gap: 28px;

    .label {
      display: flex;
      align-items: center;
      gap: 7px;

      svg {
        width: 20px;
        stroke: $gray-600;
      }

      > p {
        color: $gray-600;
        @include text-style(body);
      }
    }

    .score {
      display: flex;
      align-items: center;
      gap: 10px;

      .cut_off_score_input {
        color: $bluishGray-700;
        width: 40px;
        text-align: center;
        @include text-style(t1);

        &:focus {
          outline: none;
          color: #455573;
          border-bottom: 1.5px solid #CCDAFF;
        }

        &.not_yet {
          color: $gray-500;
        }
      }

      .unit {
        color: $coz-blue-600;
        @include text-style(body, bold);
      }
    }
  }

  .button_wrapper {
    width: 52px;
    height: 36px;
  }
}

