@use 'src/styles/colors.scss' as c;
@use 'src/styles/textStyles' as ts;
@use 'src/styles/variables' as v;

$row-height: 40px;

.row {
  width: 100%;
  height: $row-height;
  min-height: $row-height;
}

.selectbox {
  width: 100%;
  height: $row-height;
  padding: 0 16px;
  color: c.$gray-800;
  border-color: c.$bluishGray-300;
  @include ts.text-style(t1, medium);

  &.solid {
    color: c.$gray-600;
    background-color: c.$bluishGray-100;
  }

  &.done {
    color: c.$bluishGray-700;
    background-color: c.$bluishGray-100;
    border: none;
    > div {
      opacity: 0;
    }
  }
}

.input {
  color: c.$gray-800;
  border-color: c.$bluishGray-300;
  font-size: 14px !important;
  &:focus:not(.invalid),
  &:focus-within:not(.invalid) {
    color: inherit;
  }
  &::placeholder {
    font-size: 14px !important;
  }

  &:disabled {
    background-color: c.$white;
    border: 1px solid c.$bluishGray-300;
  }

  &.done {
    color: c.$bluishGray-700;
    background-color: c.$bluishGray-100;
    border: none;
  }
}

.searchSelectbox {
  &.done {
    background-color: c.$bluishGray-100;
    border: none;
    > p {
      color: c.$bluishGray-700;
    }
    > svg {
      opacity: 0;
    }
  }
}

.logicBox {
  &_wrapper {
    width: 100%;
    min-height: 112px;
    padding: 28px 0 28px 0;
    background-color: c.$white;
    border: 1px solid c.$bluishGray-300;
    border-radius: 8px;

    position: relative;
  }

  &_type {
    width: 120px;
    position: absolute;
    top: 36px;
    left: 35px;
  }

  &_statement {
    &_wrapper {
      display: grid;
      row-gap: 4px;
    }

    &_add {
      margin-top: 16px;
      padding-left: 167px;
    }
  }

  &_action {
    color: c.$bluishGray-500;
    position: absolute;
    transition: color 0.1s ease;
    cursor: pointer;

    &:hover {
      color: c.$bluishGray-700;
    }

    &:disabled {
      display: none;
    }

    &.move {
      top: 0;
      left: -10px;
      transform: translateX(-100%);
    }

    &.more {
      top: 0;
      right: -8px;
      transform: translateX(100%);

      &:hover {
        .dropdown {
          display: block;
        }
      }

      .dropdown {
        display: none;
        margin-top: 8px;
        width: 89px;
        color: c.$bluishGray-600;
        z-index: v.$zlayout__popover;
        background: c.$bluishGray-50;
        border-radius: 5px;
        border: 1px solid c.$bluishGray-400;
        @extend .text-style-t1;
        font-weight: 500;
        list-style: none;
        li {
          width: 100%;
          padding: 7px;
          text-align: center;
          border-bottom: 1px solid c.$bluishGray-400;
          * {
            width: 100%;
          }
          &:last-of-type {
            border-bottom: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.statements {
  display: grid;
  row-gap: 4px;
}

.statement {
  &_wrapper {
    width: 100%;
    padding: 8px 35px 8px 167px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;

    position: relative;
    transition: background-color 0.1s ease;

    &:hover {
      background-color: c.$bluishGray-50;
      .statement_delete {
        display: block;

        &:disabled {
          display: none;
        }
      }
    }
  }

  &_add {
    width: fit-content;
    margin-top: 12px;
    margin-left: 167px;

    @include ts.text-style(t1, medium);
    color: c.$coz-blue-600;
    text-decoration: underline;

    &:disabled {
      display: none;
    }
  }

  &_delete {
    display: none;
    color: c.$bluishGray-500;

    position: absolute;
    top: 20px;
    right: 11px;
    cursor: pointer;
    transition: color 0.1s ease;

    &:hover {
      color: c.$bluishGray-700;
    }
  }
}

.if_then {
  display: grid;
  row-gap: 36px;

  .then {
    position: relative;

    &_keyword_box {
      width: 120px;
      height: 40px;
      padding: 0 16px;
      background-color: c.$coz-blue-50;
      color: c.$coz-blue-600;
      border-radius: 4px;
      @include ts.text-style(t1, medium);

      display: flex;
      align-items: center;

      position: absolute;
      top: 8px;
      left: 36px;
    }
  }
}

// storybook
.sb_logicBox_container {
  min-width: 1000px;

  display: grid;
  row-gap: 16px;
}
