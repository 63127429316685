@use 'styles/styles' as *;

.container {
  width: 100%;
  height: 219px;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $bluishGray-300;

  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    @extend .text-style-body;
    color: $bluishGray-600;
    font-weight: 500;
  }
}
