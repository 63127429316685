@use 'styles/styles' as *;

.button {
  border-radius: 6px;
  padding: 0 12px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  .spinner_box {
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.align_center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.align_right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.small {
  min-width: 89px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  .left_icon {
    margin-right: 4px;
  }

  .right_icon {
    margin-left: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.medium {
  min-width: 105px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  .left_icon {
    margin-right: 6px;
  }

  .right_icon {
    margin-left: 6px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.large {
  min-width: 346px;
  height: 48px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  .left_icon {
    margin-right: 12px;
  }

  .right_icon {
    margin-left: 12px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.big {
  min-width: 180px;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  .left_icon {
    margin-right: 12px;
  }

  .right_icon {
    margin-left: 12px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.full_width {
  width: 100%;
  min-width: unset;
}

.solid {
  background-color: $coz-blue-600;
  color: $white;

  &:hover {
    background-color: $coz-blue-400;
  }

  &:disabled {
    background-color: $bluishGray-300;
  }
}

.light_solid {
  background-color: $coz-blue-200;
  color: $coz-blue-600;

  &:hover {
    background-color: $coz-blue-400;
  }

  &:disabled {
    background-color: $bluishGray-300;
    color: $bluishGray-500;
  }
}

.skeleton {
  border: 1px solid $coz-blue-600;
  color: $coz-blue-600;

  &:hover {
    border: none;
    color: $white;
    background-color: $coz-blue-400;
  }

  &:disabled {
    border: none;
    color: $white;
    background-color: $bluishGray-300;
  }
}

.line_skeleton {
  border: 1px solid $coz-blue-600;
  color: $coz-blue-600;

  &:hover {
    background-color: $coz-blue-200;
  }

  &:disabled {
    color: $bluishGray-500;
    background-color: transparent;
    border: 1px solid $bluishGray-500;
  }
}

.skeleton_gray {
  border: 1px solid $gray-400;
  color: $gray-600;

  &:hover {
    background-color: $gray-100;
  }

  &:disabled {
    color: $bluishGray-500;
    background-color: transparent;
    border: 1px solid $bluishGray-500;
  }
}

.solid_gray {
  color: $bluishGray-600;
  background-color: $bluishGray-100;

  &:hover {
    background-color: $bluishGray-200;
  }

  &:disabled {
    color: $bluishGray-400;
    background-color: $bluishGray-100;
  }
}
