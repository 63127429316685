@use '/src/styles/colors.scss' as c;
@use '/src/styles/textStyles.scss' as ts;

.wrapper {
  width: 100%;
  height: auto;
  min-height: 197px;
  margin-top: 40px;
  padding: 24px 16px 16px 16px;
  background-color: c.$white;
  border-radius: 8px;
  border: 1px solid c.$bluishGray-300;
}

.template {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &_title {
    @include ts.text-style(t1, medium);
    color: c.$gray-600;
  }

  &_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &_selectBox {
    height: 40px;
    text-align: left;

    &_wrapper {
      width: 415px;
    }
  }

  &_evaluation {
    position: relative;

    > button.sync_btn {
      margin-right: 8px;
      border: none;
      color: c.$coz-blue-600;
    }

    &_tooltip {
      padding: 4px 8px;
      border-radius: 4px;
      background-color: c.$bluishGray-100;
      color: c.$bluishGray-600;
      @include ts.text-style(t2, regular);
      white-space: nowrap;

      transform: translate(-50%, -100%);
      position: absolute;
      top: -4px;
      left: 50%;
      display: none;
    }

    &_btn.alreadyAnnounced:hover ~ &_tooltip.alreadyAnnounced,
    &_btn.numberNotSelected:hover ~ &_tooltip.numberNotSelected {
      display: block;
    }
  }
}

hr.divider {
  margin: 32px 0 16px 0;
  border-color: c.$bluishGray-200;
}

.evaluation {
  height: 36px;
  display: flex;
  justify-content: space-between;

  &_setting {
    display: flex;
    align-items: center;
    column-gap: 48px;
  }

  &_setting_item {
    display: flex;
    align-items: center;

    .label {
      @include ts.text-style(t1, medium);
      color: c.$gray-600;
      margin-right: 8px;
    }

    .value {
      display: flex;
      @include ts.text-style(t1, bold);
      color: c.$coz-blue-600;
    }
  }

  &_actions {
    display: flex;
    column-gap: 16px;
  }
}

.downloadModal {
  &_radioGroup {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.hidden {
  display: none;
}

.eval_failed_applications {
  margin: 10px 0 26px;
  max-height: 194px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  gap: 8px 20px;
  @include ts.text-style(t1, medium);
  background: c.$bluishGray-10;
  border-radius: 8px;
  padding: 0 12px 12px;
  color: c.$bluishGray-800;

  > .table_head {
    position: sticky;
    top: -1px;
    left: 0;
    height: 41px;
    padding: 12px 0 8px;
    margin-bottom: -4px;
    background: c.$bluishGray-10;
    color: c.$bluishGray-600;
  }
}
