@use 'styles/styles' as *;

.set_editor_page_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 63px;
}

.main_contents {
  padding: 30px 0 20px;
}

.content_footer {
  width: 960px;
  display: flex;
  justify-content: space-between;
}

.date_info {
  display: flex;
  align-items: flex-end;
  color: $bluishGray-600;
  font-weight: 400;
}

.button_box {
  button {
    margin-left: 10px;
  }
}
