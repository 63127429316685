@use 'styles/styles' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @extend .text-style-h4;
  color: $gray-800;
  width: 100%;
  text-align: center;
}
