@use 'styles/styles' as *;

.modal_wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.nontitle_alert {
  margin: 11px 15px 0;

  color: $peach-500;
}
