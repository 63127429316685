@use 'styles/styles' as *;

.header {
  width: 100%;
  border-bottom: 1px solid $bluishGray-300;
  padding-top: 31px;
  padding-bottom: 18px;
}

.title {
  color: $bluishGray-800;

  &_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
  }

  &_tag {
    height: 26px;
    padding: 0 14px;
    display: flex;
    align-items: center;

    border-radius: 8px;
    background-color: $bluishGray-200;
    > p {
      @extend .text-style-t2;
      color: $bluishGray-500;
    }
  }
}

.page_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 19.5px auto 0;

  * {
    margin-right: 4px;
  }
}

.breadcrumb {
  color: $bluishGray-700;
  font-weight: 400;
}
