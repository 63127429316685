@use 'styles/styles' as *;

.blank_section {
  text-align: center;
  color: $bluishGray-500;
  padding: 36px 0 40px;
  border: 1px dashed $bluishGray-400;
  border-radius: 6px;
  @extend .text-regular;

  p {
    margin-bottom: 5px;
  }
}

.blank_notice {
  margin-bottom: 16px;
}

.product_list {
  display: grid;
  grid-template-columns: repeat(2, 427px);
  column-gap: 16px;
  row-gap: 10px;
}

.link_product {
  display: flex;
  justify-content: flex-end;
  text-decoration-line: underline;
  color: $bluishGray-500;
  margin-top: 10px;
  padding-right: 18px;
  @extend .text-style-t1;
}

.button_box {
  display: inline-block;
  width: 177px;
}
